import React from "react";
import { navigate } from "gatsby";
import { withAuthentication } from "../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../components/main";
import Button from "../../components/button";
import Input from "../../components/input";
import TextArea from "../../components/textArea";
import MissingDataPanel from "../../components/missingDataPanel";
import ContentArea from "../../components/contentArea";
import MasonryTiles from "../../components/masonryTiles";
import Consts from "../../config/consts";
import Api from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED],
        redirectPage: "/",
    },
    class Search extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();
            const params = new URLSearchParams(props.location.search);
            let param_term = params.get("term");
            this.state = {
                windowSize: window.innerWidth,
                trending: [],

                search_term: param_term || "",
                prev_search_term: "",
                searched: false,
                searching: !!param_term,
                search_results: [],
                fetchHasMore: false,
            };
        }

        componentDidMount() {
            let resize_timeout = null;
            window.onresize = (val) => {
                clearTimeout(resize_timeout);
                resize_timeout = setTimeout(() => {
                    this.setState({
                        windowSize: window.innerWidth,
                    });
                }, 200);
            };

            // If we've been given a search term through url params...
            if (this.state.search_term && this.state.search_term.length > 0) {
                this.onSearch();
            }
        }

        // On the search text field changed
        onSearchTextChanged = (text) => {
            this.setState({
                search_term: text,
            });
        };

        // On click of the search button to action the search
        onSearch = () => {
            const fetch_quantity = 40;
            if (!this.state.search_term || this.state.search_term.length <= 0) {
                // Invalid search term - ignore
                this.setState({
                    searching: false,
                    searched: false,
                });
            } else {
                // Set the searching flag
                const compareSearchTerm = this.state.search_term === this.state.prev_search_term
                this.setState(
                    {
                        searching: true,
                        prev_search_term: !compareSearchTerm && this.state.search_term
                    },
                    () => {
                        // Do the search
                        Api.market({
                            endpoint: "/content/search",
                            method: "GET",
                            data: {
                                search: this.state.search_term,
                                startIndex: compareSearchTerm ? this.state.search_results.length : 0,
                                quantity: fetch_quantity,
                            },
                        })
                            .then((results) => {
                                this.setState({
                                    search_results: compareSearchTerm ? [...this.state.search_results,...results.data]: [...results.data],
                                    searching: false,
                                    searched: true,
                                    fetchHasMore: results.data.length === fetch_quantity,
                                });
                            })
                            .catch((e) => {
                                this.setState({
                                    searching: false,
                                    searched: true,
                                    fetchHasMore: true,
                                });
                            });
                    }
                );
            }
        };

        onShowBidOverlay = (listing) => {
            if (this.main_ref) {
                this.main_ref.current.showBidOverlay(listing);
            }
        };

        render() {
            return (
                <Main
                    ref={this.main_ref}
                    title={"BMinted"}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea slim={true} extraTopPadding={true} bottomRule={true}>
                        <div className={styles.search_container}>
                            <TextArea center={true}>
                                <p className={"caps_and_spaced"}>Search for Creations & Creators.</p>
                            </TextArea>
                            <Input
                                icon={faSearch}
                                className={`${styles.input}`}
                                defaultValue={this.state.search_term}
                                autoComplete={"off"}
                                onTextChanged={this.onSearchTextChanged}
                                placeholder={"What are you looking for?"}
                            />
                            <div className={styles.button_container}>
                                <Button
                                    text={this.state.searched && this.state.search_term.length <= 0 ? "Show Trending" : "Search"}
                                    displayMode={5}
                                    hoverMode={3}
                                    large={true}
                                    disabled={this.state.searching === true}
                                    style={{
                                        margin: 0,
                                        width: "100%",
                                    }}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </div>
                    </ContentArea>
                    {this.state.searched && this.state.search_results.length <= 0 ? (
                        <ContentArea slim={true} header={"Results"}>
                            <MissingDataPanel title={"Nothing Found"} message={`I'm afraid your search terms didn't find any good results. Try changing your search and trying again.`} />
                        </ContentArea>
                    ) : this.state.searched ? (
                        <ContentArea slim={true} header={"Results"}>
                            <MasonryTiles
                                data={this.state.search_results}
                                onLoadMore={this.onSearch}
                                showLoadMore={this.state.searched && this.state.fetchHasMore}
                                windowSize={this.state.windowSize}
                                showExploreMarket={false}
                                onBid={this.onShowBidOverlay}
                            />
                        </ContentArea>
                    ) : (
                        <ContentArea slim={true} header={"Trending"}>
                            <MasonryTiles
                                contentEndpoint={"/content/trending"}
                                fetchQuantity={12}
                                windowSize={this.state.windowSize}
                                showTrending={true}
                                showLoadMore={false}
                                onBid={this.onShowBidOverlay}
                            />
                        </ContentArea>
                    )}
                </Main>
            );
        }
    }
);
