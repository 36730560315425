// extracted by mini-css-extract-plugin
export var container = "valueRow-module--container--SI+rg";
export var image = "valueRow-module--image--m4uNZ";
export var image_container = "valueRow-module--image_container--3WjEZ";
export var image_inner = "valueRow-module--image_inner--ZzA6r";
export var image_loaded = "valueRow-module--image_loaded--2x-L4";
export var light = "valueRow-module--light--qL55G";
export var text_container = "valueRow-module--text_container--lm2Sv";
export var title = "valueRow-module--title--1lIh0";
export var transparent = "valueRow-module--transparent--TcDuW";
export var value = "valueRow-module--value--BMilj";