import React from "react";
import { Link } from "gatsby";
import * as styles from "./masonryTiles.module.css";
import Tile from "./tile";
import Button from "./button";
import ValueRow from "./valueRow";
import TextArea from "./textArea";
import DisplayName from "./displayname";
import MissingDataPanel from "./missingDataPanel";
import Api from "../config/api";
import Common from "../config/common";
import Util from "../config/util";

export default class MasonryTiles extends React.Component {
    constructor(props) {
        super(props);
        this.area_ref = React.createRef();
        this.state = {
            maxFetchesAllowed: this.props.maxFetchesAllowed || -1,
            fetchesMade: 0,
            fetchQuantity: 30,
            fetchingMore: false,
            hasMoreToFetch: true,
            lastQuantityFound: 0,

            columnCount: this.getColumns(),

            // We populate the items with a fake array initially until we have
            // some real content to display.
            items: Array.from(Array(30).keys()).map((item, index) => {
                return {
                    type: "pseudo",
                };
            }),
            unorganised_items: [],

            columns: [],
            columnHeights: [],
        };
    }

    componentDidMount() {
        // Run a render first to display the psuedo items
        this.reRender(true);

        // Check the column count and fetch some real data
        this.setState(
            {
                columnCount: this.getColumns(),
            },
            () => {
                // Check if we've been given data to display or if we're fetching ourselves
                if (this.props.data && Array.isArray(this.props.data)) {
                    this.setState(
                        {
                            fetchesMade: this.state.fetchesMade + 1,
                            fetchingMore: false,
                            items: [...this.props.data],
                            unorganised_items: this.props.data,
                        },
                        () => {
                            this.reRender(true);
                        }
                    );
                } else {
                    this.reRender(true);
                    this.fetchMore();
                }
            }
        );
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.windowSize !== prevProps.windowSize) {
            const new_column_size = this.getColumns();
            if (new_column_size !== this.state.columnCount) {
                this.setState(
                    {
                        columnCount: new_column_size,
                    },
                    () => {
                        this.reRender(true);
                    }
                );
            }
        } else if (this.props.data !== prevProps.data && Array.isArray(this.props.data)) {
            this.setState(
                {
                    fetchesMade: this.state.fetchesMade + 1,
                    fetchingMore: false,
                    items: [...this.props.data],
                    unorganised_items: this.props.data,
                },
                () => {
                    this.reRender(true);
                }
            );
        } else if (!!this.props.contentEndpointParams && !Common.equals(this.props.contentEndpointParams, prevProps.contentEndpointParams)) {
            this.setState(
                {
                    fetchesMade: 0,
                    fetchingMore: false,
                    hasMoreToFetch: true,
                    columnCount: this.getColumns(),
                    lastQuantityFound: 0,
                    items: Array.from(Array(30).keys()).map((item, index) => {
                        return {
                            type: "pseudo",
                        };
                    }),
                    unorganised_items: [],
                    columns: [],
                    columnHeights: [],
                },
                () => {
                    // Run a rerender first to clear things down
                    this.reRender(true);

                    // Now lets re-fetch some data ya'll
                    this.fetchMore();
                }
            );
        }
    };

    getColumns = () => {
        let max_tile_width = 250; //300;
        let allowed_width = Math.floor((window.innerWidth * 0.9) / max_tile_width);
        if (!!this.area_ref && !!this.area_ref.current) {
            try {
                let style = window.getComputedStyle ? getComputedStyle(this.area_ref.current) : this.area_ref.currentStyle;
                allowed_width = Math.floor((Number(style.width.replace(/px/gi, "")) * 0.9) / max_tile_width);
            } catch (e) {
                console.error(e);
            }
        }
        return Math.max(2, Math.min(5, allowed_width));
    };

    reRender = (complete_render = false) => {
        const new_column_count = this.state.columnCount;
        let new_columns = [];
        let new_column_heights = [];
        let col_index = 0;
        //let col_width_est = (window.innerWidth*.7) / new_column_count;

        // let col_width_est = (Math.min(1400, window.innerWidth) / new_column_count) - (new_column_count*20);

        // console.log("Estimated col width", col_width_est);

        // // Attempt to find the first column to get the pricise sizing of it
        // const col1 = document.getElementById("column_0");
        // if (col1!==null){
        //     console.log("Found col1");
        //     col_width_est = col1.offsetWidth;
        // }
        // console.log("Estimated col width (2)", col_width_est);

        const render = (items) => {
            // I believe it'll be faster to initialise our columns in a loop here
            // over checking if we've added the value on each iteration on the loop
            // below so lets do that
            for (let c = 0; c < new_column_count; c++) {
                if (new_columns.length <= c) {
                    new_columns.push([]);
                }
                // if (new_column_heights.length <= c) {
                //     new_column_heights.push(0);
                // }
            }

            // Run through any unorganised items
            for (let i = 0; i < items.length; i++) {
                // // Find the current smallest column - we'll add this next item
                // // into that column to try and keep things as level as possible
                // for (let c = 0; c < new_column_heights.length; c++) {
                //     if (new_column_heights[c] < new_column_heights[col_index]) {
                //         col_index = c;
                //     }
                // }
                if(!items[i].type || items[i].type !== "pseudo"){
                    // Add to the column
                    new_columns[col_index].push(items[i]);

                    // Move to the next column
                    col_index = col_index + 1 >= new_column_count ? 0 : col_index + 1;
                }
                // Update our column sizes
                // new_column_heights[col_index] = Number(new_column_heights[col_index]) + Number(Math.min(800, items[i].height));
            }

            // Update the state
            this.setState({
                columnHeights: new_column_heights,
                columns: new_columns,
            });
        };

        // Check if we're doing a full render due to screen size change
        // or if we're just adding to the end
        if (!complete_render) {
            // We're not running a full clear-down re-render so we need to start
            // by pushing whatever we currently have in columns into our new_columns
            // object to give us a base to work from
            new_columns = this.state.columns;
            new_column_heights = this.state.columnHeights;

            // Then lets render in the unorganised (not-rendered) items
            render(this.state.unorganised_items);
        } else {
            // This will do a full render - we only really want to do this
            // when the screen size has been changed.
            render(this.state.items);
        }
    };

    fetchMore = () => {
        return new Promise((resolve, reject) => {
            const quantity_to_fetch = this.props.fetchQuantity || 50;
            if (this.props.contentEndpoint && (this.state.maxFetchesAllowed === -1 || this.state.fetchesMade < this.state.maxFetchesAllowed)) {
                this.setState(
                    {
                        fetchingMore: true,
                    },
                    () => {
                        // This would be a query to back-end to get more.
                        Api.market({
                            endpoint: this.props.contentEndpoint,
                            method: "GET",
                            data: {
                                ...(this.props.contentEndpointParams || {}),
                                startIndex: Number(this.state.fetchesMade === 0 ? 0 : this.state.items.length),
                                quantity: Number(quantity_to_fetch),
                            },
                        })
                            .then((results) => {
                                const at_least_this_many = 16;
                                const found_quantity = results.data.length;

                                // I'd like to always show at least 8 tiles - even if some are pseudo tiles, so
                                // lets do a count of how many items we have and pad out.
                                if (results.data.length + (this.state.fetchesMade === 0 ? 0 : this.state.items.length) < at_least_this_many) {
                                    results.data = [
                                        ...results.data,
                                        ...Array.from(Array(at_least_this_many - (results.data.length + (this.state.fetchesMade === 0 ? 0 : this.state.items.length))).keys()).map((item, index) => {
                                            return {
                                                type: "pseudo",
                                            };
                                        }),
                                    ];
                                }

                                this.setState(
                                    {
                                        fetchesMade: this.state.fetchesMade + 1,
                                        fetchingMore: false,
                                        items: this.state.fetchesMade === 0 ? [...results.data] : [...this.state.items, ...results.data],
                                        lastQuantityFound: found_quantity,
                                        unorganised_items: results.data,
                                        hasMoreToFetch: found_quantity === quantity_to_fetch,
                                    },
                                    () => {
                                        this.reRender(this.state.fetchesMade === 1 ? true : false);
                                        resolve();
                                    }
                                );
                            })
                            .catch((e) => {
                                console.log(e);
                                if (this.state.fetchesMade === 0) {
                                    this.setState({
                                        fetchesMade: this.state.fetchesMade + 1,
                                        items: [],
                                    });
                                }
                                Util.notify.error("Failed to get content with the provided parameters. Please try again.");
                            });
                    }
                );
            }
        });
    };

    render() {
        return (
            <div
                className={`
                ${styles.container} 
                ${this.props.singleLine && styles.singleLine}
            `}
            >
                {this.state.hasMoreToFetch !== true && this.state.fetchesMade > 0 && this.state.lastQuantityFound === 0 && (
                    <div className={styles.nothing_found_container}>
                        <p className={styles.nothing_found}>Nothing Found</p>
                    </div>
                )}
                <div ref={this.area_ref} id={"data_container"} className={`${styles.columnData} ${this.props.flexWrapMb && styles.flexWrapMb}`}>
                    {this.state.columns.map((items, col_index, col_arr) => (
                        <div key={`column_${col_index}`} id={`column_${col_index}`} className={styles.column}>
                            {col_index > 0 && col_index === col_arr.length - 1 && this.props.topUsers && this.props.topUsers.length > 0 && (
                                <div className={styles.leader_section}>
                                    {this.props.topUsers.map((c, c_index) => (
                                        <ValueRow
                                            key={`top_user_${c_index}`}
                                            title={<DisplayName>{`${c.displayName}`}</DisplayName>}
                                            avatar={c}
                                            //icon={c.profileImage ? `${process.env.GATSBY_STORAGE}user/${c.profileImage}` : null}
                                            value={
                                                c.featuredType === "TOP_SELLERS"
                                                    ? "Best Seller"
                                                    : c.featuredType === "TOP_BUYERS"
                                                    ? "Top Buyer"
                                                    : c.featuredType === "TOP_CREATOR"
                                                    ? "Exquisite Creator"
                                                    : "Top User"
                                            }
                                            verified={true}
                                        />
                                    ))}
                                </div>
                            )}
                            {items.map((item, item_index) => (
                                <Tile
                                    key={`tile_${col_index}_${item_index}`}
                                    content={item}
                                    darkMode={this.props.darkMode === true}
                                    hasMarginLeft={col_index !== 0}
                                    hasMarginRight={col_index !== col_arr.length - 1}
                                    noMinWidth={true}
                                    showDetail={this.props.showTileDetail !== false}
                                    showOverlayDetail={this.props.showTileOverlayDetail === true}
                                    listingMode={this.props.listingMode === true}
                                    onBid={this.props.onBid}
                                    onStopListing={this.props.onStopListing}
                                    containerStyle={{ marginTop: 0, marginBottom: this.props.windowSize < 600 ? 20 : 20, flex: "none" }}
                                />
                            ))}
                        </div>
                    ))}
                </div>
                {this.state.fetchesMade > 0 && (
                    <div className={styles.columnFooter}>
                        {this.state.items.length <= 0 ? (
                            <MissingDataPanel darkMode={this.props.darkMode === true} title={"Nothing to Display"} message={"Check back soon!"} />
                        ) : this.props.data && this.props.hasMoreToFetch && this.props.showLoadMore && this.props.onLoadMore ? (
                            <div className={styles.fetchMoreContainer}>
                                <Button displayMode={5} hoverMode={1} large={true} onClick={this.props.onLoadMore} text={this.props.loadMoreButtonText || "Load More"} />
                            </div>
                        ) : !this.props.data &&
                          this.props.showLoadMore !== false &&
                          this.state.hasMoreToFetch &&
                          this.state.fetchesMade > 0 &&
                          (this.state.maxFetchesAllowed === -1 || this.state.fetchesMade < this.state.maxFetchesAllowed) ? (
                            <div className={styles.fetchMoreContainer}>
                                <Button displayMode={5} hoverMode={1} large={true} disabled={this.state.fetchingMore === true} onClick={this.fetchMore} text={"Load More"} />
                            </div>
                        ) : (
                            this.props.showExploreMarket !== false && (
                                <TextArea center={true}>
                                    <div className={styles.nothing_found_container}>
                                        <Link to={"/explore"}>
                                            <p className={styles.nothing_found}>Explore Marketplace</p>
                                        </Link>
                                    </div>
                                </TextArea>
                                // <div className={styles.fetchMoreContainer}>
                                //     <TextArea center={true}>
                                //         <p>Want to see more? Explore the Marketplace</p>
                                //         <Button displayMode={5} hoverMode={1} large={true} to={"/explore"} text={"Explore Marketplace"} />
                                //     </TextArea>
                                // </div>
                            )
                        )}
                    </div>
                )}
            </div>
        );
    }
}
