import React from "react";
import * as styles from "./missingDataPanel.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class MissingDataPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <div className={`${styles.container} ${this.props.darkMode === true ? styles.dark_mode : ""}`}>
                <div className={styles.inner_container}>
                    {this.props.emoji ? <span className={styles.emoji}>{this.props.emoji}</span> : this.props.icon && <FontAwesomeIcon icon={this.props.icon} className={styles.icon} />}
                    {this.props.title && <p className={styles.title}>{this.props.title}</p>}
                    {this.props.message && <p className={styles.message}>{this.props.message}</p>}
                    {this.props.button && <div className={styles.button}>{this.props.button}</div>}
                </div>
            </div>
        );
    }
}
