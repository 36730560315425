import React from "react";
import * as styles from "./valueRow.module.css";
import Avatar from "./avatar";

export default class ValueRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image_loaded: false,
            image_error: false,
        };
    }

    componentDidMount() {}

    onImgLoad = () => {
        this.setState({
            image_loaded: true,
        });
    };
    onImgError = () => {
        this.setState({
            image_error: true,
        });
    };

    render() {
        return (
            <div className={`${styles.container} ${this.props.transparent ? styles.transparent : ""}`}>
                <div className={`${styles.image_container} ${this.props.border !== false ? styles.border : ""}`}>
                    {this.props.avatar ? (
                        <Avatar user={this.props.avatar} />
                    ) : (
                        <div className={`${styles.image_inner} ${this.state.image_loaded ? styles.image_loaded : ""}  ${this.props.onDark ? styles.light : ""}`}>
                            {!this.state.image_error && <img onLoad={this.onImgLoad} onError={this.onImgError} src={this.props.icon} className={styles.image} />}
                        </div>
                    )}
                </div>
                <div className={styles.text_container}>
                    <p className={styles.title}>{this.props.title}</p>
                    <p className={styles.value}>{this.props.value}</p>
                </div>
            </div>
        );
    }
}
